import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createStockProductsList,
  editStockProductsList,
  getStockProductsList,
  deleteStockProductsList,
} from 'entities/Stock';
import { StockProductsList, StockProductsListData } from 'shared/interfaces';
import { DEFAULT_ERROR_TEXT } from 'shared/lib';

export const initialState: { data: StockProductsList[]; error: string } = {
  data: [],
  error: '',
};

export const stockProductsListModel = createSlice({
  name: 'stockProductsList',
  initialState,
  reducers: {
    setStockProductsList: (state, { payload }: PayloadAction<StockProductsList[]>) => {
      state.data = payload;
    },
    setStockProductsListError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setStockProductsList, setStockProductsListError } = stockProductsListModel.actions;

export const editStockProductsListAsync = (input: StockProductsListData) => async () => {
  try {
    const response = await editStockProductsList(input);

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: editStockProductsListAsync', e);
  }
};

export const createStockProductsListAsync = (input: StockProductsListData) => async () => {
  try {
    const response = await createStockProductsList(input);

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: createStockProductsListAsync', e);
  }
};

export const getStockProductsListAsync =
  (input: {userId: number, stockProductId: number | null}) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      dispatch(setStockProductsListError(''));
      const response = await getStockProductsList(input);

      if (response.status !== 200) {
        dispatch(setStockProductsListError(DEFAULT_ERROR_TEXT));
        return;
      }

      dispatch(setStockProductsList(response.data));
      return response.data;
    } catch (e) {
      console.log('ERROR: getStockProductsListAsync', e);
    }
  };

export const deleteStockProductsListAsync =
  (input: {userId: number, stockProductId: number | null}) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      dispatch(setStockProductsListError(''));
      const response = await deleteStockProductsList(input);

      if (response.status !== 200) {
        dispatch(setStockProductsListError(DEFAULT_ERROR_TEXT));
        return;
      }
      return response.data;
    } catch (e) {
      console.log('ERROR: getStockProductsListAsync', e);
    }
  };
// export const setStockProductsListAsync =
//   (input: StockProductsListData) => async (dispatch: Dispatch, getState: RootState) => {
//     try {
//       const response = await fetchStockProductsList(input);

//       return response.data;
//     } catch (e) {
//       console.log('ERROR: setStockProductsListAsync', e);
//     }
//   };

export const selectStockProductsList = (state: RootState) => state.stockProductsList.data;
export const selectStockProductsListError = (state: RootState) => state.stockProductsList.error;
export const reducer = stockProductsListModel.reducer;
