import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createUsersList,
  editUsersList,
  fetchUsersList,
  getUsersList,
  deleteUsersList,
} from 'entities/Accounts';
import { UsersList, UsersListData } from 'shared/interfaces';
import { DEFAULT_ERROR_TEXT } from 'shared/lib';

export const initialState: { data: UsersList[]; error: string } = {
  data: [],
  error: '',
};

export const usersListModel = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    setUsersList: (state, { payload }: PayloadAction<UsersList[]>) => {
      state.data = payload;
    },
    setUsersListError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setUsersList, setUsersListError } = usersListModel.actions;

export const editUserAsync = (input: UsersListData) => async () => {
  try {
    const response = await editUsersList(input);

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: editUserAsync', e);
  }
};

export const createUserAsync = (input: UsersListData) => async () => {
  try {
    const response = await createUsersList(input);

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: createUserAsync', e);
  }
};

export const getUsersListAsync =
  (userId: number, accountId: number | null) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      dispatch(setUsersListError(''));
      const response = await getUsersList(userId, accountId);

      if (response.status !== 200) {
        dispatch(setUsersListError(DEFAULT_ERROR_TEXT));
        return;
      }

      dispatch(setUsersList(response.data));
      return response.data;
    } catch (e) {
      console.log('ERROR: getUsersListAsync', e);
    }
  };

export const deleteUsersListAsync =
  (input: {userId: number, accountId: number | null}) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      dispatch(setUsersListError(''));
      const response = await deleteUsersList(input);

      if (response.status !== 200) {
        dispatch(setUsersListError(DEFAULT_ERROR_TEXT));
        return;
      }
      return response.data;
    } catch (e) {
      console.log('ERROR: getUsersListAsync', e);
    }
  };
export const setUsersListAsync =
  (input: UsersListData) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await fetchUsersList(input);

      return response.data;
    } catch (e) {
      console.log('ERROR: setUsersListAsync', e);
    }
  };

export const selectUsersList = (state: RootState) => state.usersList.data;
export const selectUsersListError = (state: RootState) => state.usersList.error;
export const reducer = usersListModel.reducer;
