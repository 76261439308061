import { Button, Col } from 'react-bootstrap';
import styles from './ProductsList.module.scss';
import { useState } from 'react';
import { ProductTile } from 'shared/ui';
import { CatalogList } from 'entities/CatalogList/interfaces';

export const ProductsList = ({
  defaultCountShow = 4,
  defaultNextShow = 4,
  productsList,
  limitCount = null,
}: {
  defaultCountShow?: number;
  defaultNextShow?: number;
  productsList: CatalogList[];
  limitCount?: number | null;
}) => {
  const [productsListCountShow, setProductListCountShow] = useState(defaultCountShow);
  const handleProductListCount = () =>
    setProductListCountShow(productsListCountShow + defaultNextShow);

  const filteredProductsList = productsList.slice(0, limitCount || productsList.length);

  return (
    <>
      <div className={styles.productTile__wrapper}>
        <div className={styles.productTile__list}>
          {filteredProductsList.slice(0, productsListCountShow).map((item: CatalogList) => (
            <Col key={item.id} xs={item.size === 'big' ? 6 : 3}>
              <ProductTile product={item} />
            </Col>
          ))}
        </div>
        {productsListCountShow < filteredProductsList.length && (
          <Button onClick={handleProductListCount} className={styles.block__more} variant='light'>
            Показать ещё
          </Button>
        )}
      </div>
    </>
  );
};
