import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PopularList } from '../interfaces';
import { getPopularList } from '../api/fetchPopularList';

export const initialState: { data: PopularList[]; error: string } = {
  data: [],
  error: '',
};

export const popularModel = createSlice({
  name: 'popular',
  initialState,
  reducers: {
    setPopularValue: (state, { payload }: PayloadAction<PopularList[]>) => {
      state.data = payload;
    },
    setPopularError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setPopularValue, setPopularError } = popularModel.actions;

export const getPopularAsync = () => async (dispatch: Dispatch, getState: RootState) => {
  try {
    const response = await getPopularList();

    dispatch(setPopularValue(response.data));

    return response.data;
  } catch (e) {
    console.log('ERROR: getPopularAsync', e);
  }
};

export const selectPopular = (state: RootState) => state.popular.data;
export const selectPopularError = (state: RootState) => state.popular.error;
export const reducer = popularModel.reducer;
