import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchRolesList } from 'shared/api/fetchRolesList';
import { RolesList } from 'shared/interfaces';

export const initialState: { data: RolesList[] } = { data: [] };

export const rolesListModel = createSlice({
  name: 'rolesList',
  initialState,
  reducers: {
    setRolesList: (state, { payload }: PayloadAction<RolesList[]>) => {
      state.data = payload;
    },
  },
});

export const { setRolesList } = rolesListModel.actions;

export const getRolesListAsync =
  (userId: number) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await fetchRolesList(userId);

      if (response.status !== 200) {
        return;
      }

      dispatch(setRolesList(response.data));

      return response.data;
    } catch (e) {
      console.log('ERROR: getRolesListAsync', e);
    }
  };

export const selectRolesList = (state: RootState) => state.rolesList.data;
export const selectRolesListOptions = (state: RootState) => {
  const rolesList = selectRolesList(state);
  return rolesList.map(role => ({
    label: role.name,
    value: role.id.toString(),
  }));
};

export const reducer = rolesListModel.reducer;
