import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchManufacturers } from 'shared/api/fetchManufacturers';
import { Manufacturers, ManufacturersInput } from 'shared/interfaces';

export const initialState: { data: Manufacturers[] } = { data: [] };

export const manufacturersModel = createSlice({
  name: 'manufacturers',
  initialState,
  reducers: {
    setManufacturers: (state, { payload }: PayloadAction<Manufacturers[]>) => {
      state.data = payload;
    },
  },
});

export const { setManufacturers } = manufacturersModel.actions;

export const getManufacturersAsync =
  (input: ManufacturersInput) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await fetchManufacturers(input);

      if (response.status !== 200) {
        return;
      }

      dispatch(setManufacturers(response.data));

      return response.data;
    } catch (e) {
      console.log('ERROR: getManufacturersAsync', e);
    }
  };

export const selectManufacturers = (state: RootState) => state.manufacturers.data;
export const selectManufacturersOptions = (state: RootState) => {
  const manufacturers = selectManufacturers(state);
  return manufacturers.map(role => ({
    label: role.name,
    value: role.id.toString(),
  }));
};

export const reducer = manufacturersModel.reducer;
