import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const initialState: { data: string } = { data: '' };

export const headerModel = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderInfo: (state, { payload }: PayloadAction<string>) => {
      state.data = payload;
    },
  },
});

export const { setHeaderInfo } = headerModel.actions;

export const selectHeaderInfo = (state: RootState) => state.header.data;

export const reducer = headerModel.reducer;
