interface Cookie {
  name: string;
  value: string;
  expiresDays?: number;
  session?: boolean;
}

export const writeCookie = ({ name, value, expiresDays, session }: Cookie) => {
  if (expiresDays) {
    let expires = new Date();
    expires.setDate(expires.getDate() + expiresDays);
    document.cookie = `${name}=${value}; expires=${expires.toISOString()}; path=''`;
  } else if (session) {
    document.cookie = `${name}=${value}; session; path=''`;
  }
};

export const getCookie = (name: string) => {
  let matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const clearAuthCookies = () => {
  const cookies = document.cookie;

  cookies.split(';').forEach(cookie => {
    const cookieName = cookie.split('=')[0].trim();
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  });
};
