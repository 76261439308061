import { Col } from 'react-bootstrap';
import styles from './ReviewTile.module.scss';
import { Link } from 'react-router-dom';
import { Review } from 'shared/interfaces';
import cn from 'classnames';
import { VIDEO_CAT_ID } from 'shared/lib';

export const ReviewTile = ({ review }: { review: Review }) => {
  return (
    <Col xs={4}>
      <div className={styles.previewTile__item}>
        {!!review.image && (
          <div className={styles.previewTile__image}>
            <Link to={review.url}>
              <img src={review.image} alt='' />
              {review.categories?.find(cat => cat.term_id === VIDEO_CAT_ID) && (
                <div className={styles.previewTile__video} />
              )}
            </Link>
          </div>
        )}
        {review?.tags?.length ? (
          <div className={styles.tags}>
            {review.tags.slice(0, 3).map(tag => (
              <div key={tag.term_id} className={styles.tags__item}>
                {tag?.url ? <Link to={tag.url}>{tag.name}</Link> : tag.name}
              </div>
            ))}
            {review.tags.length > 3 && (
              <div className={cn(styles.tags__item, styles.show)}>...</div>
            )}
          </div>
        ) : null}
        {review?.date ? <div className={styles.previewTile__date}>{review.date}</div> : null}
        <Link to={review.url}>
          <div className={styles.previewTile__name}>{review.title}</div>
        </Link>
      </div>
    </Col>
  );
};
