import { deleteServicesListAsync, getServicesListAsync } from 'entities/Services';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useGetServicesList = ({
  userId,
  serviceId,
}: {
  userId?: number;
  serviceId?: number;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchServicesList = async () => {
    setLoading(true);
    userId && (await dispatch(getServicesListAsync({ userId, serviceId: serviceId || null })));
    setLoading(false);
  };

  const handleDeleteUser = async (serviceId: number) => {
    userId && serviceId && (await dispatch(deleteServicesListAsync({ userId, serviceId })));
  };

  useEffect(() => {
    fetchServicesList();
  }, []);

  return { loading, setLoading, fetchServicesList, handleDeleteUser };
};
