import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CatalogList } from '../interfaces';
import { getCatalogList } from '../api/fetchCatalogList';

export const initialState: { data: CatalogList[]; error: string } = {
  data: [],
  error: '',
};

export const catalogModel = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalogValue: (state, { payload }: PayloadAction<CatalogList[]>) => {
      state.data = payload;
    },
    setCatalogError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setCatalogValue, setCatalogError } = catalogModel.actions;

export const getCatalogAsync = (catalog: string) => async (dispatch: Dispatch, getState: RootState) => {
  try {
    const response = await getCatalogList(catalog);

    dispatch(setCatalogValue(response.data));

    return response.data;
  } catch (e) {
    console.log('ERROR: getCatalogAsync', e);
  }
};

export const selectCatalog = (state: RootState) => state.catalog.data;
export const selectCatalogError = (state: RootState) => state.catalog.error;
export const reducer = catalogModel.reducer;
