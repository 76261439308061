import { useState } from 'react';
import styles from './BaseError.module.scss';
import { Button } from 'react-bootstrap';

interface Props {
  error: string;
  title?: string;
  onRepeat?: () => Promise<void>;
}

export const BaseError = ({ error, title = 'Ошибка', onRepeat }: Props) => {
  const [loading, setLoading] = useState(false);
  const handleRepeat = async () => {
    setLoading(true);
    await onRepeat?.();
    setLoading(false);
  };

  return (
    <div className={styles.error}>
      <div>{error}</div>
      {onRepeat && (
        <Button onClick={handleRepeat}>
          Повторить
        </Button>
      )}
    </div>
  );
};
