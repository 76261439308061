import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PageInfo } from 'shared/interfaces';
import { getPageInfo } from 'shared/api/fetchPageInfo';

export const initialState: { data: PageInfo; error: string } = {
  data: {},
  error: '',
};

export const pageModel = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.data = payload;
    },
    setPageError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setPageInfo, setPageError } = pageModel.actions;

export const getPageInfoAsync =
  (page: string) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await getPageInfo(page);

      dispatch(setPageInfo(response.data));

      return response.data;
    } catch (e) {
      console.log('ERROR: getPageInfoAsync', e);
    }
  };

export const selectPageInfo = (state: RootState) => state.page.data;
export const selectPageError = (state: RootState) => state.page.error;
export const reducer = pageModel.reducer;
