import { ReactNode } from 'react';
import cn from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './DashBoardLayout.module.scss';

interface Props {
  headerSlot?: ReactNode;
  children?: ReactNode;
  footerSlot?: ReactNode;
}

export const DashBoardLayout = ({ headerSlot, children, footerSlot }: Props) => {
  return (
    <div className={cn({ [styles.wrap]: headerSlot })}>
      {headerSlot}
      <div className={styles.layout}>{children}</div>
      {footerSlot}
    </div>
  );
};
