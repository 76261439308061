import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createServicesList,
  editServicesList,
  getServicesList,
  deleteServicesList,
} from 'entities/Services';
import { Services, ServicesListData } from 'shared/interfaces';
import { DEFAULT_ERROR_TEXT } from 'shared/lib';

export const initialState: { data: Services[]; error: string } = {
  data: [],
  error: '',
};

export const servicesListModel = createSlice({
  name: 'servicesList',
  initialState,
  reducers: {
    setServicesList: (state, { payload }: PayloadAction<Services[]>) => {
      state.data = payload;
    },
    setServicesListError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setServicesList, setServicesListError } = servicesListModel.actions;

export const editServicesAsync = (input: ServicesListData) => async () => {
  try {
    const response = await editServicesList(input);

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: editServicesAsync', e);
  }
};

export const createServicesAsync = (input: ServicesListData) => async () => {
  try {
    const response = await createServicesList(input);

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: createServicesAsync', e);
  }
};

export const getServicesListAsync =
  (input: {userId: number, serviceId: number | null}) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      dispatch(setServicesListError(''));
      const response = await getServicesList(input);

      if (response.status !== 200) {
        dispatch(setServicesListError(DEFAULT_ERROR_TEXT));
        return;
      }

      dispatch(setServicesList(response.data));
      return response.data;
    } catch (e) {
      console.log('ERROR: getServicesListAsync', e);
    }
  };

export const deleteServicesListAsync =
  (input: {userId: number, serviceId: number | null}) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      dispatch(setServicesListError(''));
      const response = await deleteServicesList(input);

      if (response.status !== 200) {
        dispatch(setServicesListError(DEFAULT_ERROR_TEXT));
        return;
      }
      return response.data;
    } catch (e) {
      console.log('ERROR: getServicesListAsync', e);
    }
  };
// export const setServicesListAsync =
//   (input: ServicesListData) => async (dispatch: Dispatch, getState: RootState) => {
//     try {
//       const response = await fetchServicesList(input);

//       return response.data;
//     } catch (e) {
//       console.log('ERROR: setServicesListAsync', e);
//     }
//   };

export const selectServicesList = (state: RootState) => state.servicesList.data;
export const selectServicesListError = (state: RootState) => state.servicesList.error;
export const reducer = servicesListModel.reducer;
