import { useState } from 'react';

export interface UseModalReturn {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

export const useModal = (initialState?: boolean): UseModalReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(() => initialState ?? false);

  const closeModal = () => setIsOpen(false);

  const openModal = () => setIsOpen(true);

  return {
    isOpen,
    closeModal,
    openModal,
  };
};
