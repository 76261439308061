import { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './Preloader.module.scss';
import cn from 'classnames';

interface Props {
  headerSlot?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const Preloader = ({ headerSlot, children, className }: Props) => {
  return (
    <div className={cn(styles.preloader, className && className)}>
      <Spinner animation='border' role='status'>
        {children}
      </Spinner>
    </div>
  );
};
