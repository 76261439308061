import cn from 'classnames';
import styles from './PopularList.module.scss';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { useGetPopularList } from '../..';
import { PopularList as PopularListProps } from '../../interfaces';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import noPhoto from './images/nophoto.png';

export const PopularList = () => {
  const { popular } = useGetPopularList();

  return (
    <div className={styles.popular}>
      <Row>
      {popular?.map(item => (
        <Col key={item.id} xs={3}>
          <Link to="/watch" className={styles.item}>
            <div className={styles.title}>
              {item.title}
            </div>
            <div className={styles.image}>
              {item.image ? <img className={styles.photo} src={item.image} alt={item.title} /> : <img className={styles.nophoto} src={noPhoto} alt={item.title} />}
            </div>
          </Link>
        </Col>
      ))}
      </Row>
    </div>
  );
};
