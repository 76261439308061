import { configureStore } from '@reduxjs/toolkit';
import { usersListModel } from 'entities/Accounts';
import { headerModel } from 'shared/model/header';
import { userInfoModel } from 'shared/model/userInfo';
import { userTokenModel } from 'entities/UserToken';
import { catalogModel } from 'entities/CatalogList';
import { rolesListModel } from 'shared/model/rolesList';
import { manufacturersModel } from 'shared/model/manufacturers';
import { modelsModel } from 'shared/model/models';
import { enginesModel } from 'shared/model/engines';
import { pageModel } from 'shared/model/pageInfo';
import { servicesListModel } from 'entities/Services';
import { reviewsListModel } from 'shared/model/reviewsList';
import { stockProductsListModel } from 'entities/Stock';
import { popularModel } from 'entities/Popular';

export const store = configureStore({
  reducer: {
    page: pageModel.reducer,
    catalog: catalogModel.reducer,
    header: headerModel.reducer,
    manufacturers: manufacturersModel.reducer,
    models: modelsModel.reducer,
    engines: enginesModel.reducer,
    rolesList: rolesListModel.reducer,
    popular: popularModel.reducer,
    servicesList: servicesListModel.reducer,
    reviewsList: reviewsListModel.reducer,
    stockProductsList: stockProductsListModel.reducer,
    userInfo: userInfoModel.reducer,
    userToken: userTokenModel.reducer,
    usersList: usersListModel.reducer,
  },
});
