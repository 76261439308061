export enum UserPermissions {
  isCanSeeCarsCatalogPage = '1', // Может видеть страницу Каталог автомобилей
  isCanSeeWarehousePage = '2', // Может видеть страницу Склад
  isCanSeeActualWarehousePage = '3', // Может видеть страницу Фактический склад
  isCanSeeCalculatePage = '4', // Может видеть страницу Калькулятора
  isCanSeeCabinetPage = '5', // Может видеть страницу Личный кабинет
  isCanSeeAccessPanelPage = '6', // Может видеть страницу Панель управления доступом
  isCanEditCarsCatalog = '7', // Может изменять Каталог автомобилей
  isCanEditWarehouse = '8', // Может изменять Склад
  isCanEditActualWarehous = '9', // Может изменять Фактический склад
  isCanEditCalculate = '10', // Может изменять Калькулятор
  isCanEditCabinet = '11', // Может изменять Личный кабинет
  isCanEditServices = '12', // Может изменять Услуги
}