import { getPageInfoAsync, selectPageInfo } from 'shared/model';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePageInfo = (pageValues: string) => {
  const dispatch = useDispatch();
  const page = useSelector(selectPageInfo);
  const [loading, setLoading] = useState(false);

  const fetchCatalogList = async () => {
    setLoading(true);
    await dispatch(getPageInfoAsync(pageValues));
    setLoading(false);
  };

  useEffect(() => {
    fetchCatalogList();
  }, [pageValues]);

  return { loading, setLoading, page, fetchCatalogList };
};
