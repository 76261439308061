import React, { forwardRef, useState } from 'react';
import cn from 'classnames';
import { Form } from 'react-bootstrap';

import styles from './Input.module.scss';
import { useDebouncedCallback } from 'use-debounce';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  sizeInput?: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  message?: string;
  button?: React.ReactNode;
  error?: string | boolean;
  success?: string | boolean;
  beforeElement?: React.ReactNode;
  afterElement?: React.ReactNode;
  muted?: string;
  name?: string;
  defaultValue?: string | number | string[];
  debounce?: number;
  onDebounce?: any;
  labelAfterInput?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const {
    label,
    defaultValue = '',
    message,
    button,
    error = '',
    success = '',
    beforeElement,
    afterElement,
    className,
    muted,
    debounce = 0,
    onDebounce,
    labelAfterInput = false,
    ...other
  } = props;

  const errorMessage = typeof error === 'string' ? error : '';
  const successMessage = typeof success === 'string' ? success : '';

  const [value, setInputValue] = useState(defaultValue);

  const debounced = useDebouncedCallback(value => {
    setInputValue(value);
    onDebounce?.(value);
  }, debounce);

  return (
    <div className={cn(styles['input-outer'], className)}>
      <div
        className={cn(
          styles['form-floating'],
          styles[value ? 'not-empty' : 'empty'],
          [error && styles['error']],
          [success && styles['success']],
        )}
      >
        {beforeElement && <span className={cn(styles['input-before'])}>{beforeElement}</span>}

        <Form.Group>
          {label && !labelAfterInput ? (
            <Form.Label className={cn(styles['form-label'])}>{label}</Form.Label>
          ) : null}
          <input
            aria-invalid={error ? 'true' : 'false'}
            className={cn(
              'input-field',
              'form-control',
              error && 'is-invalid',
              success && 'is-valid',
            )}
            ref={ref}
            defaultValue={value}
            onChange={e => debounced(e.target.value)}
            {...other}
          />
          {label && labelAfterInput ? (
            <Form.Label className={cn(styles['form-label'])}>{label}</Form.Label>
          ) : null}
          {(error || success) && (
            <Form.Text
              className={cn(
                styles['text-muted'],
                error && 'invalid-feedback',
                success && 'valid-feedback',
              )}
            >
              {error ? errorMessage : success ? successMessage : message}
            </Form.Text>
          )}
        </Form.Group>

        {afterElement && <span className={cn(styles['input-after'])}>{afterElement}</span>}
      </div>
    </div>
  );
});

export default Input;
