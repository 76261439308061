import { Button, Col, Row } from 'react-bootstrap';
import styles from './Pagination.module.scss';

interface Props {
  onMoreButton?: (page: number) => void;
  onPage: (page: number) => void;
  page: number;
  countPages: number;
}

export const Pagination = ({ onMoreButton, onPage, page, countPages }: Props) => {
  return (
    <>
      {onMoreButton && page < countPages ? (
        <Row className={styles.buttonMore}>
          <Col>
            <Button onClick={() => onMoreButton(page + 1)} variant='light'>
              Показать еще
            </Button>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <div className={styles.list}>
            {countPages > 3 && page > 2 ? (
              <Button onClick={() => onPage(page - 1)} variant='light'>
                &lt;
              </Button>
            ) : (
              ''
            )}

            {page === countPages ? (
              <Button onClick={() => onPage(page - 2)} variant='light'>
                {page - 2}
              </Button>
            ) : (
              ''
            )}

            {page - 1 >= 1 ? (
              <Button onClick={() => onPage(page - 1)} variant='light'>
                {page - 1}
              </Button>
            ) : (
              ''
            )}

            {
              <Button onClick={() => onPage(page)} variant='dark'>
                {page}
              </Button>
            }

            {page + 1 <= countPages ? (
              <Button onClick={() => onPage(page + 1)} variant='light'>
                {page + 1}
              </Button>
            ) : (
              ''
            )}

            {page === 1 ? (
              <Button onClick={() => onPage(page + 2)} variant='light'>
                {page + 2}
              </Button>
            ) : (
              ''
            )}

            {countPages > 4 && page + 1 < countPages ? (
              <>
                <Button variant='link'>...</Button>
                <Button onClick={() => onPage(countPages)} variant='light'>
                  {countPages}
                </Button>
              </>
            ) : (
              ''
            )}

            {countPages > 3 && page + 1 <= countPages ? (
              <Button onClick={() => onPage(page + 1)} variant='light'>
                &gt;
              </Button>
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Pagination;
