import { httpClient } from 'shared/api/baseApi';
import { ServicesListData } from 'shared/interfaces';

export const getServicesList = (input: { userId: number; serviceId: number | null }) =>
  httpClient.post('/services_list/', input);

export const editServicesList = (input: ServicesListData) =>
  httpClient.post('/services_edit/', input);

export const createServicesList = (input: ServicesListData) =>
  httpClient.post('/services_create/', input);

export const deleteServicesList = (input: { userId: number; serviceId: number | null }) =>
  httpClient.post('/services_delete/', input);
