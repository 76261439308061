
export const toOptions = <T extends { [key: string]: any }>(
  array: T[],
  propName: string,
  valueKey = 'id',
): any[] => {
  return array.map(item => ({
    value: item[valueKey].toString(),
    label: item[propName] ?? '',
  }));
};

export const fmResponseMultipleValuesToArr = (value?: string) =>
  value?.split(',').map(item => item.trim()) ?? [];

export const optiosValuesToFmString = (values?: any[]) =>
  values?.map(item => item.value).join(',') ?? '';

export const getSingleOptionValue = <T extends { [key: string]: any }>(
  object: T,
  name: string,
): string => {
  return object[name]?.[0]?.value;
};
