import { shallowEqual, useSelector } from 'react-redux';
import Logo from 'shared/images/logo.svg';
import IconWhatsapp from 'shared/images/whatsapp.svg';
import IconTelegram from 'shared/images/telegram.svg';
import { selectHeaderInfo, selectUserInfo } from 'shared/model';
import styles from './Footer.module.scss';
import { Button, Col, Container, Dropdown, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import {
  NAV_ACCOUNTS,
  NAV_ACCOUNTS_USER,
  NAV_BRANDS,
  NAV_CATALOG,
  NAV_LOGOUT,
  NAV_PERSONAL,
  NAV_MAN,
  NAV_WAREHOUSE,
  logout,
  trapSpacesForRequiredFields,
  useUserPermissions,
  NAV_ROOT,
  NAV_ABOUT,
} from 'shared/lib';
import cn from 'classnames';
import { Input } from 'shared/ui';
import { useForm } from 'react-hook-form';

interface FormDataProps {
  email: string;
}

export const Footer = () => {
  const { userInfo, headerInfo } = useSelector(
    (state: RootState) => ({
      userInfo: selectUserInfo(state),
      headerInfo: selectHeaderInfo(state),
    }),
    shallowEqual,
  );
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormDataProps>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { isShowAccessPanelPage } = useUserPermissions();

  const onSubmit = async (data: FormDataProps) => {
    // clearErrors();
    // setLoading(true);
    // const result = userInfo && (await dispatch(setUsersListAsync(data)));
    // setLoading(false);
    // if (result) {
    //   setSuccess(true);
    //   fetchUsersList();
    // } else {
    //   setErrorMessage('Попробуйте повторить позднее');
    // }
  };

  return (
    <footer className={styles.footer}>
      <Container>
        <Row className={styles.footer__top}>
          <Col xs={2}>
            <div className={styles.logo}>
              <Link to={NAV_ROOT}>
                <img src={Logo} alt='Логотип' />
              </Link>
            </div>
            <div className={styles.info}>
              <div className={styles.info__key}>Сall-center</div>
              <div className={styles.info__value}>
                <a href='tel:+79992343245'>+7 999 234 32 45</a>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.info__key}>Работаем ежедневно</div>
              <div className={styles.info__value}>с 9:00 до 21.00</div>
            </div>
            <div className={styles.socials}>
              <ul className={styles.socials__list}>
                <li className={styles.socials__item}>
                  <a href='tg://resolve?domain=79992343245'>
                    <img src={IconTelegram} alt='' />
                  </a>
                </li>
                <li className={styles.socials__item}>
                  <a href='https://api.whatsapp.com/send?phone=79992343245'>
                    <img src={IconWhatsapp} alt='' />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={2}>
            <nav className={styles.nav__list}>
              <div className={styles.nav__item}>
                <div className={styles.nav__title}>О компании</div>
              </div>
              <div className={styles.nav__item}>
                <Link to={NAV_ABOUT} className={styles.nav__link}>
                  О нас
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Магазины
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Контакты
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Энциклопедия брендов
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Обзоры часов
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Новости
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Акции
                </Link>
              </div>
            </nav>
          </Col>
          <Col xs={2}>
            <nav className={styles.nav__list}>
              <div className={styles.nav__item}>
                <div className={styles.nav__title}>Бренды часов</div>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Delbana
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Cover
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Aerowatch
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Armand Nicolet
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Swiss Military
                </Link>
              </div>
            </nav>
          </Col>
          <Col xs={2}>
            <nav className={styles.nav__list}>
              <div className={styles.nav__item}>
                <div className={styles.nav__title}>Сервис</div>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Послепродажная настройка
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Гарантийный ремонт
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Ремонт часов
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Обслуживание часов
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Гравировка
                </Link>
              </div>
            </nav>
          </Col>
          <Col xs={2}>
            <nav className={styles.nav__list}>
              <div className={styles.nav__item}>
                <div className={styles.nav__title}>Покупателям</div>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  On-line заказ
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Доставка
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Гарантии
                </Link>
              </div>
              <div className={styles.nav__item}>
                <Link to='#' className={styles.nav__link}>
                  Возврат/Обмен
                </Link>
              </div>
            </nav>
          </Col>
          <Col xs={2} className='ml-auto'>
            <div className={styles.column}>
              <div className={styles.column__title}>Задать вопрос</div>
              <div className={styles.column__email}>
                <a href='mailto:ask@chrono.uz'>ask@chrono.uz</a>
              </div>
              <div className={styles.column__button}>
                <Button variant='link'>Заполнить форму</Button>
              </div>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <InputGroup>
                <Row>
                  <Col>
                    <Form.Group>
                      <Input
                        label='Подписка'
                        placeholder='Почта'
                        error={errors.email && errors.email.message}
                        {...register('email', {
                          validate: value =>
                            trapSpacesForRequiredFields(value) || 'Обязательное поле',
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type='submit' variant='dark' className={styles.button}>
                      Подписаться
                    </Button>
                  </Col>
                </Row>
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <Row className={cn(styles.footer__bottom, 'align-items-center')}>
          <Col xs='auto'>
            <div className={styles.copyright}>2024 © часы</div>
          </Col>
          <Col xs='auto' className='ml-auto'>
            <div className={styles.navBottom}>
              <div className={styles.navBottom__item}>
                <Link to='/'>Политика конфиденциальности</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
