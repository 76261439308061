import styles from './Breadcrumbs.module.scss';
import { Link } from 'react-router-dom';
import { Breadcrumb as BreadcrumbProps } from 'shared/interfaces';

export const Breadcrumbs = ({ items }: { items: BreadcrumbProps[] }) => {
  if (!items?.length) return null;

  return (
    <div className={styles.breadcrumbs}>
      {items.map(item => {
        if (item?.isLast) {
          return <div className={styles.breadcrumbs__item}>{item.children}</div>;
        }

        return (
          <div className={styles.breadcrumbs__item}>
            <Link to={item?.url ?? '#'}>{item.children}</Link>
          </div>
        );
      })}
    </div>
  );
};
