export const loaderReactAsync = (
  loadComponentFunction: Function,
  root: HTMLElement,
  props: Object = {},
): Promise<void> => {
  return Promise.all([import('react'), import('react-dom'), loadComponentFunction()])
    .then(
      ([
        { default: React },
        {
          default: { hydrate },
        },
        { default: Component },
      ]) => {
        hydrate(<Component {...props} />, root);
      },
    )
    .catch(err => console.error(err));
};
