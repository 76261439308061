import { Col } from 'react-bootstrap';
import styles from './BrandTile.module.scss';
import { Link } from 'react-router-dom';
import { Brand } from 'shared/interfaces';

export const BrandTile = ({ item }: { item: Brand }) => {
  return (
    <Link to={item.url} className={styles.brandTile__item}>
      <div className={styles.brandTile__image}>
        <img src={item.images.mini} alt='' />
      </div>
    </Link>
  );
};
