import cn from 'classnames';
import styles from './CatalogList.module.scss';
import bannerImage from './image/banner.jpg';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Pagination, ProductTile } from 'shared/ui';
import { useGetCatalogList } from '../..';
import { useLocation } from 'react-router-dom';
import { CatalogList as CatalogListProps } from 'entities/CatalogList/interfaces';
import { FiltersList } from 'entities/Filters/interfaces';
import { useEffect, useState } from 'react';

interface Props {
  filters: FiltersList[];
}

export const CatalogList = ({ filters }: Props) => {
  const { pathname, search } = useLocation();
  const { catalog } = useGetCatalogList(pathname);
  const [filteredCatalogList, setFilteredCatalogList] = useState([...catalog]);
  const someSelectedFilters =
    filters.filter(filter => filter?.values?.some(item => item.selected)) || [];
  const [pageShow, setPageShow] = useState(40);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [startItem, setStartItem] = useState(0);
  const [endItem, setEndItem] = useState(40);

  const handleNavItems = (selectedKey: string | null) => {
    clearPagination();

    if (selectedKey === 'price') {
      setFilteredCatalogList(
        [...catalog].sort((a: CatalogListProps, b: CatalogListProps) => +a.price - +b.price),
      );
    } else if (selectedKey === 'discount') {
      setFilteredCatalogList(
        [...catalog].sort((a: CatalogListProps, b: CatalogListProps) =>
          a.price > b.price ? 1 : -1,
        ),
      );
    } else if (selectedKey === 'newItems') {
      setFilteredCatalogList(
        [...catalog].sort((a: CatalogListProps, b: CatalogListProps) => a.id - b.id),
      );
    } else {
      setFilteredCatalogList(
        [...catalog].sort((a: CatalogListProps, b: CatalogListProps) =>
          a.title > b.title ? 1 : -1,
        ),
      );
    }
  };

  const handleMoreButton = (page: number) => {
    setPageCurrent(page);
    setEndItem(page * pageShow);
  };

  const handlePage = (page: number) => {
    setPageCurrent(page);
    setStartItem((page - 1) * pageShow);
    setEndItem(page * pageShow);
  };

  const clearPagination = () => {
    setPageCurrent(1);
    setStartItem(0);
    setEndItem(40);
  };

  useEffect(() => {
    setFilteredCatalogList(catalog);
  }, [catalog]);

  console.log({ pathname, search, catalog, someSelectedFilters, filteredCatalogList });
  return (
    <div className={styles.catalog}>
      <div className={styles.banner}>
        <img src={bannerImage} alt='' />
      </div>
      <div className={styles.sorting}>
        <div className={styles.sorting__count}>В наличии {catalog.length} позиции</div>
        <Nav className={styles.sorting__list} onSelect={handleNavItems} defaultActiveKey='price'>
          <Nav.Item className={styles.sorting__item}>
            <Nav.Link eventKey='price'>По цене</Nav.Link>
          </Nav.Item>
          <Nav.Item className={styles.sorting__item}>
            <Nav.Link eventKey='discount'>По скидке</Nav.Link>
          </Nav.Item>
          <Nav.Item className={styles.sorting__item}>
            <Nav.Link eventKey='newItems'>По новизне</Nav.Link>
          </Nav.Item>
          <Nav.Item className={styles.sorting__item}>
            <Nav.Link eventKey='abs'>По алфавиту</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      {someSelectedFilters.length > 0 && (
        <div className={styles.selected}>
          <div className={styles.selected__list}>
            <div className={cn(styles.selected__item, styles.all)}>
              Очистить
              <div className={styles.selected__close} />
            </div>
            {someSelectedFilters.map(filter => {
              if (filter?.values?.length) {
                return filter?.values?.map(item => {
                  if (item.selected) {
                    return (
                      <div className={styles.selected__item}>
                        {item.name}
                        <div className={styles.selected__close} />
                      </div>
                    );
                  }

                  return null;
                });
              }
            })}
          </div>
        </div>
      )}
      <div className={styles.listing}>
        <Row className={styles.nomargin}>
          {filteredCatalogList.slice(startItem, endItem).map((item: CatalogListProps) => (
            <Col key={item.id} xs={3}>
              <ProductTile product={item} />
            </Col>
          ))}
        </Row>
        {filteredCatalogList.length > pageShow && (
          <Pagination
            page={pageCurrent}
            countPages={Math.ceil(filteredCatalogList.length / pageShow)}
            onPage={handlePage}
            onMoreButton={handleMoreButton}
          />
        )}
      </div>
    </div>
  );
};
