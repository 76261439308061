import { deleteStockProductsListAsync, getStockProductsListAsync } from 'entities/Stock';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useGetStockProductsList = ({
  userId,
  stockProductId,
}: {
  userId?: number;
  stockProductId?: number;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchStockProductsList = async () => {
    setLoading(true);
    userId && (await dispatch(getStockProductsListAsync({ userId, stockProductId: stockProductId || null })));
    setLoading(false);
  };

  const handleDeleteUser = async (stockProductId: number) => {
    userId && stockProductId && (await dispatch(deleteStockProductsListAsync({ userId, stockProductId })));
  };

  useEffect(() => {
    fetchStockProductsList();
  }, []);

  return { loading, setLoading, fetchStockProductsList, handleDeleteUser };
};
