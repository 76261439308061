import { Button, Col } from 'react-bootstrap';
import styles from './ProductTile.module.scss';
import { Link } from 'react-router-dom';
import IconFavorite from 'shared/images/favorite.svg';
import IconFavoriteHover from 'shared/images/favorite_hover.svg';
import { CatalogList } from 'entities/CatalogList/interfaces';

const clearLink = (url: string) => {
  let clear = url.replace('http://localhost:3000', '');
  clear = clear.replace('https://uz-chrono.ru', '');
  clear = clear.replace('http://uz-chrono.ru', '');
  return clear;
};

export const ProductTile = ({ product }: { product: CatalogList }) => {
  return (
    <div className={styles.productTile__column}>
      <div className={styles.productTile__favorite} />
      <Link to={clearLink(product.url)} className={styles.productTile__item}>
        {product?.plumbs?.length ? (
          <div className={styles.productTile__plates}>
            {product?.plumbs.map(plate => (
              <div className={styles.productTile__plate}>{plate.label}</div>
            ))}
          </div>
        ) : null}
        <div className={styles.productTile__image}>
          <img src={product.image} alt='' />
        </div>
        <div className={styles.productTile__title}>{product.title}</div>
        <div className={styles.productTile__prices}>
          <div className={styles.productTile__basePrice}>{product.price}</div>
          {product.price_old ? (
            <div className={styles.productTile__oldPrice}>{product.price_old}</div>
          ) : null}
        </div>
      </Link>
      <div className={styles.productTile__buttons}>
        <Button className={styles.productTile__inBasket} variant='dark'>
          В корзину
        </Button>
        <Button className={styles.productTile__oneClick} variant='link'>
          Купить в 1 клик
        </Button>
      </div>
    </div>
  );
};
