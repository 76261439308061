import { lazy, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Header } from 'widgets/Header';
import {
  NAV_BRANDS,
  NAV_CATALOG,
  NAV_CATALOG_ITEM,
  NAV_HOME,
  NAV_PASSWORD_RESTORE,
  NAV_PERSONAL,
  NAV_MAN,
  NAV_WAREHOUSE,
  NAV_WAREHOUSE_ITEM,
  useUserPermissions,
  NAV_ABOUT,
  NAV_PRODUCT_ITEM,
  NAV_POPULAR,
  usePageInfo,
} from 'shared/lib';
import { getUserInfoAsync } from 'shared/model';
import { CenteredLayout, DashBoardLayout, Preloader } from 'shared/ui';
import { selectUserLogin, selectUserToken } from 'entities/UserToken';
import { Footer } from 'widgets/Footer';

const AboutPage = lazy(() => import('./about'));
const BrandsPage = lazy(() => import('./brands'));
const HomePage = lazy(() => import('./home'));
const CatalogPage = lazy(() => import('./catalog'));
const ServicePage = lazy(() => import('./services'));
const WarehousePage = lazy(() => import('./warehouse'));
const PersonalPage = lazy(() => import('./personal'));
const ProductPage = lazy(() => import('./product'));
const PopularPage = lazy(() => import('./popular'));

export const Routing = () => {
  const dispatch = useDispatch();

  const { token, login } = useSelector(
    (state: RootState) => ({
      token: selectUserToken(state),
      login: selectUserLogin(state),
    }),
    shallowEqual,
  );

  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getUserInfoAsync(login));
    setIsLoading(false);
  };

  const { pathname, search } = useLocation();
  const { page } = usePageInfo(pathname);

  console.log({page});

  const { isShowAccessPanelPage, isEditServices } = useUserPermissions();

  const dashBoardLayoutRoutes = [
    {
      path: NAV_CATALOG,
      component: CatalogPage,
    },
    {
      path: NAV_CATALOG_ITEM,
      component: CatalogPage,
    },
    {
      path: NAV_PRODUCT_ITEM,
      component: ProductPage,
    },
    {
      path: NAV_POPULAR,
      component: PopularPage,
    },
    {
      path: NAV_MAN,
      component: ServicePage,
    },
    {
      path: NAV_ABOUT,
      component: AboutPage,
    },
    {
      path: NAV_WAREHOUSE,
      component: WarehousePage,
    },
    {
      path: NAV_WAREHOUSE_ITEM,
      component: WarehousePage,
    },
    {
      path: NAV_BRANDS,
      component: BrandsPage,
    },
    {
      path: NAV_PERSONAL,
      component: PersonalPage,
    },

    {
      path: NAV_HOME,
      component: HomePage,
    },
  ];

  const centeredLayoutRoutes = [
    {
      path: NAV_PASSWORD_RESTORE,
      component: HomePage,
    },
  ];

  console.log({ dashBoardLayoutRoutes });

  const allDashBoardLayoutPaths = dashBoardLayoutRoutes.map(route => route.path);
  const allCenteredLayoutPaths = centeredLayoutRoutes.map(route => route.path);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Switch>
      <Route path={allDashBoardLayoutPaths}>
        <DashBoardLayout headerSlot={<Header />} footerSlot={<Footer />}>
          {dashBoardLayoutRoutes.map(({ path, component }) => (
            <Route key={path} exact path={path} component={component} />
          ))}
        </DashBoardLayout>
      </Route>
      <Route path={allCenteredLayoutPaths}>
        <CenteredLayout>
          {centeredLayoutRoutes.map(({ path, component }) => (
            <Route key={path} exact path={path} component={component} />
          ))}
        </CenteredLayout>
      </Route>
      <Redirect to={NAV_HOME} />
    </Switch>
  );
};
