import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchUserInfo } from 'shared/api/fetchUserInfo';
import { UserInfo } from 'shared/interfaces/UserInfo';
import { DEFAULT_ERROR_TEXT, fmResponseMultipleValuesToArr } from 'shared/lib';
import { parseColumns, parseResourceData } from 'shared/lib/fmpMethods';

export const initialState: { data?: UserInfo } = { data: undefined };

export const userInfoModel = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, { payload }: PayloadAction<UserInfo | undefined>) => {
      state.data = payload;
    },
  },
});

export const { setUserInfo } = userInfoModel.actions;

export const getUserInfoAsync =
  (login: string) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await fetchUserInfo(login);

      if (response.status !== 200) {
        return;
      }

      dispatch(setUserInfo(response.data[0]));

      return response.data;
    } catch (e) {
      console.log('ERROR: getUserInfoAsync', e);
    }
  };

export const selectUserInfo = (state: RootState) => state.userInfo.data;
export const selectUserAuthids = (state: RootState) => {
  const userInfo = selectUserInfo(state);
  return fmResponseMultipleValuesToArr(userInfo?.authids) || [];
};

export const reducer = userInfoModel.reducer;
