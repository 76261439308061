import { useSelector } from 'react-redux';
import { UserPermissions } from 'shared/interfaces';
import { selectUserAuthids } from 'shared/model';

export const useUserPermissions = () => {
  const authIdsUserArray = useSelector(selectUserAuthids);

  const searchUserAuthority = (authIds: string[]) =>
    authIds.some(id => authIdsUserArray.includes(id));

  // Может просматривать страницу Каталог автомобилей
  const isShowCarsCatalogPage = searchUserAuthority([UserPermissions.isCanSeeCarsCatalogPage]);

  // Может просматривать страницу Склад
  const isShowWarehousePage = searchUserAuthority([UserPermissions.isCanSeeWarehousePage]);

  // Может просматривать страницу Фактический склад
  const isShowActualWarehousePage = searchUserAuthority([
    UserPermissions.isCanSeeActualWarehousePage,
  ]);

  // Может просматривать страницу Калькулятора
  const isShowCalculatePage = searchUserAuthority([UserPermissions.isCanSeeCalculatePage]);

  // Может просматривать страницу Личный кабинет
  const isShowCabinetPage = searchUserAuthority([UserPermissions.isCanSeeCabinetPage]);

  // Может просматривать страницу Панель управления доступом
  const isShowAccessPanelPage = searchUserAuthority([UserPermissions.isCanSeeAccessPanelPage]);

  // Может изменять Каталог автомобилей
  const isEditCarsCatalog = searchUserAuthority([UserPermissions.isCanEditCarsCatalog]);

  // Может изменять Склад
  const isEditWarehouse = searchUserAuthority([UserPermissions.isCanEditWarehouse]);

  // Может изменять Фактический склад
  const isEditActualWarehous = searchUserAuthority([UserPermissions.isCanEditActualWarehous]);

  // Может изменять Калькулятор
  const isEditCalculate = searchUserAuthority([UserPermissions.isCanEditCalculate]);

  // Может изменять Личный кабинет
  const isEditCabinet = searchUserAuthority([UserPermissions.isCanEditCabinet]);

  // Может изменять Услуги
  const isEditServices = searchUserAuthority([UserPermissions.isCanEditServices]);

  return {
    authIdsUserArray,
    isShowCarsCatalogPage,
    isShowWarehousePage,
    isShowActualWarehousePage,
    isShowCalculatePage,
    isShowCabinetPage,
    isShowAccessPanelPage,

    isEditCarsCatalog,
    isEditWarehouse,
    isEditActualWarehous,
    isEditCalculate,
    isEditCabinet,
    isEditServices,
  };
};
