import { FC, ReactNode } from 'react';
import styles from './PageTop.module.scss';

interface PageTopProps {
  titleSlot?: ReactNode;
  rightSlot?: ReactNode;
}

export const PageTop: FC<PageTopProps> = ({ titleSlot, rightSlot }) => {
  return (
    <div className={styles.top}>
      <h1>{titleSlot}</h1>
      {rightSlot}
    </div>
  );
};
