import { FC, ReactNode } from 'react';
import styles from './CenteredLayout.module.scss';

interface CenteredLayoutProps {
  children: ReactNode;
}

export const CenteredLayout: FC<CenteredLayoutProps> = ({ children }) => {
  return <div className={styles.layout}>{children}</div>;
};
