import { Button, Col, Row } from 'react-bootstrap';
import styles from './ReviewsList.module.scss';
import { Link } from 'react-router-dom';
import { Review } from 'shared/interfaces';
import cn from 'classnames';
import { useState } from 'react';
import { ReviewTile } from 'shared/ui';
import { REVIEW_CAT_ID, useGetReviewsList } from 'shared/lib';

export const ReviewsList = ({
  defaultCountShow = 6,
  defaultNextShow = 6,
  limitCount = null,
  catId = REVIEW_CAT_ID,
  tagsName,
  title,
}: {
  defaultCountShow?: number;
  defaultNextShow?: number;
  limitCount?: number | null;
  catId?: number;
  tagsName?: string[];
  title?: string;
}) => {
  const [reviewListCountShow, setReviewListCountShow] = useState(defaultCountShow);
  const handleReviewListCount = () => setReviewListCountShow(reviewListCountShow + defaultNextShow);
  const { reviewsList } = useGetReviewsList();

  const filteredReviewsList = reviewsList
    .filter(review => {
      return (
        review.categories?.find(cat => cat.term_id === catId) &&
        (tagsName ? review?.tags && review.tags?.find(tag => tagsName.includes(tag.name)) : true)
      );
    })
    .slice(0, limitCount || reviewsList.length);

  return filteredReviewsList.length ? (
    <>
      {title ? (
        <Row>
          <Col>
            <div className={styles.title}>{title}</div>
          </Col>
        </Row>
      ) : null}
      <Row>
        {filteredReviewsList.slice(0, reviewListCountShow).map((item: Review) => (
          <ReviewTile review={item} key={item.id} />
        ))}
        <div className={styles.button}>
          {reviewListCountShow < filteredReviewsList.length && (
            <Button onClick={handleReviewListCount} className={styles.block__more} variant='light'>
              Показать ещё
            </Button>
          )}
        </div>
      </Row>
    </>
  ) : null;
};
