import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchReviewsList } from 'shared/api/fetchReviewsList';
import { Review } from 'shared/interfaces';

export const initialState: { data: Review[] } = { data: [] };

export const reviewsListModel = createSlice({
  name: 'reviewsList',
  initialState,
  reducers: {
    setReviewsList: (state, { payload }: PayloadAction<Review[]>) => {
      state.data = payload;
    },
  },
});

export const { setReviewsList } = reviewsListModel.actions;

export const getReviewsListAsync = () => async (dispatch: Dispatch, getState: RootState) => {
  try {
    const response = await fetchReviewsList();

    dispatch(setReviewsList(response.data));

    return response.data;
  } catch (e) {
    console.log('ERROR: getReviewsListAsync', e);
  }
};

export const selectReviewsList = (state: RootState) => state.reviewsList.data;
export const selectReviewsListOptions = (state: RootState) => {
  const reviewsList = selectReviewsList(state);
  return reviewsList.map(role => ({
    label: role.title,
    value: role.id.toString(),
  }));
};

export const reducer = reviewsListModel.reducer;
