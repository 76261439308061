export const NAV_ROOT = '/';
export const NAV_ACCOUNTS = '/accounts';
export const NAV_ABOUT = '/about';
export const NAV_ACCOUNTS_CREATE = '/accounts/create';
export const NAV_ACCOUNTS_USER = '/accounts/user/:userId';
export const NAV_ACCOUNTS_EDIT = '/accounts/edit/:userId';
export const NAV_ROLES = '/roles';
export const NAV_ROLES_EDIT = '/roles/edit/:roleId';
export const NAV_ROLES_CREATE = '/roles/create';

export const NAV_CATALOG = '/watch';
export const NAV_CATALOG_ITEM = '/watch/*';
export const NAV_CATALOG_MAN = `${NAV_CATALOG}/man`;
export const NAV_CATALOG_WOMAN = `${NAV_CATALOG}/woman`;
export const NAV_PRODUCT_ITEM = '/product/:productId';
export const NAV_POPULAR = '/popular';
export const NAV_MAN = '/services';
export const NAV_MAN_CREATE = '/services/create';
export const NAV_MAN_ITEM = '/services/edit/:serviceId';
export const NAV_WAREHOUSE = '/warehouse';
export const NAV_WAREHOUSE_CREATE = '/warehouse/create';
export const NAV_WAREHOUSE_ITEM = '/warehouse/:stockId';
export const NAV_ACTUALE_WAREHOUSE = '/actuale-warehouse';
export const NAV_ACTUALE_WAREHOUSE_ITEM = '/actuale-warehouse/:stockId';
export const NAV_BRANDS = '/brands';
export const NAV_PERSONAL = '/personal';
export const NAV_ACCESS_PANEL = '/access-panel';

export const NAV_HOME = '/';
export const NAV_PASSWORD_RESTORE = '/password-restore';
export const NAV_LOGOUT = '/logout';

export const REVIEW_CAT_ID = 335;
export const VIDEO_CAT_ID = 334;

export const JobStatuses = {
  TOTAL: '1',
  NEW: '2',
  IN_WORK: '3',
  REQUEST: '4',
  NOT_APPROVAL: '5',
  CLOSED: '6',
};

export const JobStatusesName = {
  TOTAL: 'Все',
  NEW: 'Новые',
  IN_WORK: 'В работе',
  REQUEST: 'Ожидание',
  NOT_APPROVAL: 'Ожидают подтверждения',
  CLOSED: 'Завершённые',
};

export const userRole = {
  SUPER_ADMINISTRATOR: {
    id: 1,
    name: 'Супер-администратор',
  },
  ADMINISTRATOR: {
    id: 2,
    name: 'Администратор',
  },
  USER: {
    id: 3,
    name: 'Пользователь',
  },
};

export const DEFAULT_ERROR_TEXT = 'Что-то пошло не так, попробуйте снова';

export const transportList = [
  {
    id: 1,
    icon: null,
    name: 'Легковые автомобили',
  },
  {
    id: 2,
    icon: null,
    name: 'Грузовые автомобили',
  },
  {
    id: 3,
    icon: null,
    name: 'Внедорожное применение',
  },
  {
    id: 4,
    icon: null,
    name: 'Мотоциклы',
  },
];

export const filtersTypes = [
  {
    id: 1,
    name: 'Воздушный фильтр',
  },
  {
    id: 2,
    name: 'Масляный фильтр',
  },
  {
    id: 3,
    name: 'Топливный фильтр',
  },
  {
    id: 4,
    name: 'Воздушный фильтр салона',
  },
  {
    id: 5,
    name: 'Остальные фильтры',
  },
];
