import { getCatalogAsync, selectCatalog } from '../..';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useGetCatalogList = (catalogValues: string) => {
  const dispatch = useDispatch();
  const catalog = useSelector(selectCatalog);
  const [loading, setLoading] = useState(false);

  const fetchCatalogList = async () => {
    setLoading(true);
    !catalog?.length && await dispatch(getCatalogAsync(catalogValues));
    setLoading(false);
  };

  useEffect(() => {
    fetchCatalogList();
  }, []);

  return { loading, setLoading, catalog, fetchCatalogList };
};
