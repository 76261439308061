import { NAV_HOME } from 'shared/lib/constants';
import { clearAuthCookies } from './cookies';

export const logout = () => {
  clearAuthCookies();
  window.localStorage.setItem(`${env.REACT_APP_PROJECT}_token`, '');
  window.localStorage.setItem(`${env.REACT_APP_PROJECT}_login`, '');

  if (window.location.pathname !== NAV_HOME) {
    window.location.href = NAV_HOME;
  }
};