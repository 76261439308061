import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchModels } from 'shared/api/fetchModels';
import { Models, ModelsInput } from 'shared/interfaces';

export const initialState: { data: Models[] } = { data: [] };

export const modelsModel = createSlice({
  name: 'models',
  initialState,
  reducers: {
    setModels: (state, { payload }: PayloadAction<Models[]>) => {
      state.data = payload;
    },
  },
});

export const { setModels } = modelsModel.actions;

export const getModelsAsync =
  (input: ModelsInput) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await fetchModels(input);

      if (response.status !== 200) {
        return;
      }

      dispatch(setModels(response.data));

      return response.data;
    } catch (e) {
      console.log('ERROR: getModelsAsync', e);
    }
  };

export const selectModels = (state: RootState) => state.models.data;
export const selectModelsOptions = (state: RootState, manufactureId: number) => {
  const models = selectModels(state);
  return models.filter(model => model.manufactureId === manufactureId).map(model => ({
    label: model.name,
    value: model.id.toString(),
  }));
};

export const reducer = modelsModel.reducer;
