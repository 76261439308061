import axios from 'axios';
import { getApiConnections } from 'shared/lib/apiConnections';
import { logout } from 'shared/lib/logout';

export const httpClient = axios.create({
  baseURL: `${env.REACT_APP_URL}/myplugin/v1`,
  timeout: 600000,
  validateStatus: status => status !== 401,
});

httpClient.interceptors.request.use(
  config => {
    // @ts-ignore
    getApiConnections().openConnection(config.loaderInline, config.url);
    return config;
  },
  error => {
    getApiConnections().closeConnection(error.config.url);
    return error;
  }
);

httpClient.interceptors.response.use(
  response => {
    getApiConnections().closeConnection(response.config.url);
    return response;
  },
  // @ts-ignore
  (error: any) => {
    getApiConnections().closeConnection(error.config.url);

    // @ts-ignore
    if (401 === pathOr(400, ['response', 'status'], error)) {
      logout();
    }
  }
);