import { shallowEqual, useSelector } from 'react-redux';
import Logo from 'shared/images/logo.svg';
import IconSearch from 'shared/images/magnifier.svg';
import IconFavorite from 'shared/images/favorites.svg';
import IconPersonal from 'shared/images/personal.svg';
import IconBasket from 'shared/images/basket.svg';
import { selectHeaderInfo, selectUserInfo } from 'shared/model';
import styles from './Header.module.scss';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import {
  NAV_ACCOUNTS,
  NAV_ACCOUNTS_USER,
  NAV_BRANDS,
  NAV_CATALOG,
  NAV_LOGOUT,
  NAV_PERSONAL,
  NAV_MAN,
  NAV_WAREHOUSE,
  logout,
  useUserPermissions,
  NAV_ROOT,
  NAV_CATALOG_MAN,
  NAV_CATALOG_WOMAN,
} from 'shared/lib';
import cn from 'classnames';
import { Input } from 'shared/ui';

export const Header = () => {
  const { userInfo, headerInfo } = useSelector(
    (state: RootState) => ({
      userInfo: selectUserInfo(state),
      headerInfo: selectHeaderInfo(state),
    }),
    shallowEqual,
  );

  const { isShowAccessPanelPage } = useUserPermissions();

  console.log({ userInfo });

  return (
    <>
      <header className={styles.header}>
        <Container>
          <Row className={styles.header__top} align='middle'>
            <Col xs='auto'>
              <div className={styles.row}>
                <div className={styles.key}><span>Сall-center</span></div>
                <div className={cn(styles.value, styles.strong)}>
                  <a href='tel:+79992343245'>+7 999 234 32 45</a>
                </div>
              </div>
            </Col>
            <Col xs='auto' className={styles.online}>
              <div className={styles.row}>
                <div className={cn(styles.key, styles.online)}><span>Мы on-line</span></div>
                <div className={styles.value}>
                  <a href='https://api.whatsapp.com/send?phone=79992343245'>WhatsApp</a>
                </div>
                <div className={styles.value}>
                  <a href='tg://resolve?domain=79992343245'>Telegram</a>
                </div>
              </div>
            </Col>
            <Col xs='auto' className='ml-auto'>
              <Nav className={styles.list}>
                <Nav.Item>
                  <NavLink to="#">Доставка</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="#">Оплата</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="#">Гарантии</NavLink>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs='auto'>
              <Dropdown className={styles.personal}>
                <Dropdown.Toggle className={styles.toggle} id='dropdown-basic'>
                  RU
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">RU</Dropdown.Item>
                  <Dropdown.Item href="#">UZ</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row className={styles.header__middle} align='middle'>
            <Col xs='auto'>
              <div className={styles.logo}>
                <Link to={NAV_ROOT}><img src={Logo} alt='Логотип' /></Link>
              </div>
            </Col>
            <Col>
              <Input
                placeholder='Что будем искать?'
                className={styles.search}
                beforeElement={<img src={IconSearch} alt='' />}
              />
            </Col>
            <Col xs='auto'>
              <div className={styles.actions}>
                <div className={styles.actions__item}>
                  <Link to='/'>
                    <img src={IconFavorite} alt='' />
                  </Link>
                </div>
                <div className={styles.actions__item}>
                  <Link to='/'>
                    <img src={IconPersonal} alt='' />
                  </Link>
                </div>
                <div className={styles.actions__item}>
                  <Link to='/'>
                    <img src={IconBasket} alt='' />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className={styles.nav} align='middle'>
            <Col>
              <Nav className={styles.nav__list}>
                <Nav.Item>
                  <NavLink to={NAV_CATALOG_MAN}>Мужские часы</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={NAV_CATALOG_WOMAN}>Женские часы</NavLink>
                </Nav.Item>
                {/* <Nav.Item>
                  <NavLink to="#">Аксессуары</NavLink>
                </Nav.Item> */}
                <Nav.Item>
                  <NavLink to={NAV_BRANDS}>Бренды</NavLink>
                </Nav.Item>
                {/* <Nav.Item>
                  <NavLink to="#">Акции и скидки</NavLink>
                </Nav.Item> */}
              </Nav>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
