import { hashCode } from 'shared/lib/hashCode';

export const createApiConnections = () => {
  // Функция создает объект apiConnections в глобальном контексте
  // @ts-ignore
  if (window.apiConnections) return;

  // @ts-ignore
  window['apiConnections'] = {
    _activeConnections: {},
    _activeConnectionsInline: {},

    openConnection: function (inline = false, ...args: any[]) {
      const key = hashCode(args.join());

      if (inline) {
        this._activeConnectionsInline[key] = {
          timestamp: new Date(),
        };
      } else {
        this._activeConnections[key] = {
          timestamp: new Date(),
        };
      }
    },

    closeConnection: function (...args: any[]) {
      const key = hashCode(args.join());

      delete this._activeConnections[key];
      delete this._activeConnectionsInline[key];
    },
  };
};

export const getApiConnections = () => {
  // @ts-ignore
  if (!window.apiConnections) {
    createApiConnections();
  }

  // @ts-ignore
  return window.apiConnections;
};
