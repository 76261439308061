import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getReviewsListAsync,
  selectReviewsList,
  selectReviewsListOptions,
  selectUserInfo,
} from 'shared/model';

export const useGetReviewsList = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { reviewsList, reviewsListOptions } = useSelector(
    (state: RootState) => ({
      reviewsList: selectReviewsList(state),
      reviewsListOptions: selectReviewsListOptions(state),
      userInfo: selectUserInfo(state),
    }),
    shallowEqual,
  );

  const fetchReviewsList = async () => {
    setLoading(true);
    await dispatch(getReviewsListAsync());
    setLoading(false);
  };

  useEffect(() => {
    fetchReviewsList();
  }, []);

  return { fetchReviewsList, reviewsList, reviewsListOptions, loading };
};
