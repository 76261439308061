import { getPopularAsync, selectPopular } from '../..';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useGetPopularList = () => {
  const dispatch = useDispatch();
  const popular = useSelector(selectPopular);
  const [loading, setLoading] = useState(false);

  const fetchPopularList = async () => {
    setLoading(true);
    !popular?.length && await dispatch(getPopularAsync());
    setLoading(false);
  };

  useEffect(() => {
    fetchPopularList();
  }, []);

  return { loading, setLoading, popular, fetchPopularList };
};
