import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchEngines } from 'shared/api/fetchEngines';
import { Engines, EnginesInput } from 'shared/interfaces';

export const initialState: { data: Engines[] } = { data: [] };

export const enginesModel = createSlice({
  name: 'engines',
  initialState,
  reducers: {
    setEngines: (state, { payload }: PayloadAction<Engines[]>) => {
      state.data = payload;
    },
  },
});

export const { setEngines } = enginesModel.actions;

export const getEnginesAsync =
  (input: EnginesInput) => async (dispatch: Dispatch, getState: RootState) => {
    try {
      const response = await fetchEngines(input);

      if (response.status !== 200) {
        return;
      }

      dispatch(setEngines(response.data));

      return response.data;
    } catch (e) {
      console.log('ERROR: getEnginesAsync', e);
    }
  };

export const selectEngines = (state: RootState) => state.engines.data;
export const selectEnginesOptions = (state: RootState, manufactureId: number, modelId: number) => {
  const engines = selectEngines(state);

  return engines
    .filter(engine => engine.manufactureId === manufactureId && engine.modelId === modelId)
    .map(role => ({
      label: role.name,
      value: role.id.toString(),
    }));
};

export const reducer = enginesModel.reducer;
