import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoginFormData } from 'shared/interfaces';
import { getCookie, writeCookie } from 'shared/lib/cookies';
import { fetchNewPassword } from '../api/fetchNewPassword';
import { fetchToken } from '../api/fetchToken';

export const initialState: { token: string; login: string; error: string } = {
  token: getCookie(`${env.REACT_APP_PROJECT}_token`) ?? '',
  login: getCookie(`${env.REACT_APP_PROJECT}_login`) ?? '',
  error: '',
};

export const userTokenModel = createSlice({
  name: 'userToken',
  initialState,
  reducers: {
    setUserToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    setUserLogin: (state, { payload }: PayloadAction<string>) => {
      state.login = payload;
    },
    setUserLoginError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
});

export const { setUserToken, setUserLogin, setUserLoginError } = userTokenModel.actions;

export const getUserTokenAsync = (data: LoginFormData) => async (dispatch: Dispatch) => {
  try {
    const response = await fetchToken(data);

    if (!response || response.status !== 200) {
      dispatch(setUserLoginError(response.data.message));
      return false;
    }

    writeCookie({
      name: `${env.REACT_APP_PROJECT}_token`,
      value: response.data.token,
      session: true,
    });
    writeCookie({
      name: `${env.REACT_APP_PROJECT}_login`,
      value: data.login,
      session: true,
    });

    dispatch(setUserToken(response.data.token));
    dispatch(setUserLogin(data.login));
  } catch (e) {
    console.log('ERROR: getUserTokenAsync', e);
  }
};

export const createNewPasswordAsync = (input: any) => async (dispatch: Dispatch) => {
  try {
    const response = await fetchNewPassword(input);

    if (!response || response.status !== 200) {
      dispatch(setUserLoginError('Неверный логин или пароль'));
    }

    return response.status === 200;
  } catch (e) {
    console.log('ERROR: createNewPasswordAsync', e);
  }
};

export const selectUserLogin = (state: RootState) => state.userToken.login;
export const selectUserToken = (state: RootState) => state.userToken.token;
export const selectUserLoginError = (state: RootState) => state.userToken.error;

export const reducer = userTokenModel.reducer;
