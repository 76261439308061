import { httpClient } from 'shared/api/baseApi';
import { StockProductsListData } from 'shared/interfaces';

export const getStockProductsList = (input: { userId: number; stockProductId: number | null }) =>
  httpClient.post('/stock_products_list/', input);

export const editStockProductsList = (input: StockProductsListData) =>
  httpClient.post('/stock_products_edit/', input);

export const createStockProductsList = (input: StockProductsListData) =>
  httpClient.post('/stock_products_create/', input);

export const deleteStockProductsList = (input: { userId: number; stockProductId: number | null }) =>
  httpClient.post('/stock_products_delete/', input);
